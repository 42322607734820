const state = {};

const getters = {
  isLoggedIn: state => {
    return state.loggedIn;
  },
  getAuthToken: state => {
    return state.authToken;
  }
};
const mutations = {
  ["LOGIN"](state, data) {
    state.authToken = data.token;
    state.loggedIn = true;
  },
  ["LOGOUT"](state) {
    state.authToken = null;
    state.loggedIn = false;
  }
};
const actions = {
  async signInWithPassword({ commit }, payload) {
    await fetch("http://localhost:3000/api/login", {
      body: JSON.stringify({
        username: payload.username,
        password: payload.password
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
      },
      method: "POST"
    })
      .then(response => {
        if (response.ok) {
          console.log(response);
          return response.json();
        } else {
          console.log(response.status, "->", response.statusText);
          throw new Error(response.statusText);
        }
      })
      .then(res => {
        console.log(res);
        commit("LOGIN", { token: res.token });
      })
      .catch(status => {
        console.log(status);
      });
  },
  async logout({ commit, getters }) {
    await fetch("http://localhost:3000/api/logout", {
      body: JSON.stringify({
        token: getters["getAuthToken"]
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
      },
      method: "POST"
    }).then(response => {
      if (!response.ok) throw new Error(response.statusText);
    });
    commit("LOGOUT");
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
