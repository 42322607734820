<template>
  <v-container fill-height>
    <v-layout align-center justify-space-around>
      <v-flex xs12 sm4 md8>
        <v-card :loading="show_loading" :disabled="show_loading">
          <v-card-title>
            Register for {{ $store.getters["backend/getSelectedBarn"].name }}
          </v-card-title>
          <v-card-text>
            <v-form
              ref="form"
              @submit="validateAndSubmit"
              onSubmit="return false;"
            >
              <v-text-field
                solo
                label="Username"
                v-model="username"
                :rules="usernameRules"
              >
              </v-text-field>
              <v-text-field
                solo
                label="Choose a Password"
                v-model="password"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                v-on:click:append="showPassword = !showPassword"
                :rules="passwordRules"
              >
              </v-text-field>

              <v-text-field
                solo
                label="Repeat Password"
                :rules="passwordRepetitionRules"
                v-model="passwordRepetition"
                :type="showPasswordRepetition ? 'text' : 'password'"
                :append-icon="
                  showPasswordRepetition ? 'visibility' : 'visibility_off'
                "
                v-on:click:append="
                  showPasswordRepetition = !showPasswordRepetition
                "
              >
              </v-text-field>
            </v-form>
            <v-alert v-if="show_alert" :type="alert_type">{{
              alert_text
            }}</v-alert>
          </v-card-text>
          <v-card-actions>
            <v-btn v-on:click="goToSignIn" class="ma-4" text>
              Go to Sign In
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn v-on:click="validateAndSubmit" right>
              Register
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "SignUp",
  data() {
    return {
      show_alert: false,
      alert_type: "success",
      alert_text: "",
      show_loading: false,
      showPassword: false,
      showPasswordRepetition: false,
      username: null,
      password: null,
      passwordRepetition: null,
      usernameRules: [
        v => !!v || "Username cannot be empty!",
        v =>
          (v || "").length >= 5 ||
          "Username has to be at least 5 characters long",
        v =>
          (v || "").length <= 20 ||
          "Username has to be at most 20 characters long",
        v =>
          /^[a-zA-Z0-9._-]*$/.test(v) ||
          "Username can only contain letters, digits and the following special characters: -._"
      ],
      passwordRules: [
        v => !!v || "Passwort cannot be empty!",
        v =>
          (v || "").length >= 8 ||
          "Password has to be at least 8 characters long",
        v =>
          (v || "").length <= 70 ||
          "Password cannot be longer than 70 characters",
        v => /\d/.test(v) || "Password has to contain at least one digit.",
        v =>
          /[a-z]/.test(v) ||
          "Password has to include at least one lowercase letter.",
        v =>
          /[A-Z]/.test(v) ||
          "Password has to include at least one Uppercase letter",
        v =>
          /^[@$!%*?&€.,\-:;_()/=#*+A-Za-z0-9]*$/.test(v) ||
          "Password contains not-allowed characters!",
        v =>
          /[@$!%*?&€.,\-:;_()/=#*+]/.test(v) ||
          "Password has to include at least one of the following special characters: @$!%*?&€.,-:;_()/=#*+"
      ],
      passwordRepetitionRules: [
        v => v === this.password || "Passwords don't match!"
      ]
    };
  },
  computed: {},
  methods: {
    async validateAndSubmit() {
      if (!this.$refs.form.validate()) return;
      this.show_loading = true;
      this.show_alert = false;
      const result = await this.$store.dispatch("backend/registerAtBarn", {
        username: this.username,
        password: this.password,
        passwordRepetition: this.passwordRepetition,
        id: this.$store.getters["backend/getSelectedBarn"]._id
      });
      if (!result) {
        this.show_loading = false;
        this.alert_type = "error";
        this.alert_text = "Could not register.";
        this.show_alert = true;
      } else {
        this.show_loading = false;
        this.goToSignIn();
      }
    },
    goToSignIn() {
      this.$router.push({ name: "SignIn" });
    }
  }
};
</script>

<style scoped></style>
