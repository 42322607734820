<template>
  <v-container>
    <v-row dense no-gutters justify="end">
      <v-col class="shrink">
        <v-btn small class="ma-2" v-on:click="showLastHour">Last Hour</v-btn>
      </v-col>
      <v-col class="shrink">
        <v-btn small class="ma-2" v-on:click="showLastDay">Last Day</v-btn>
      </v-col>
      <v-col class="shrink">
        <v-btn small class="ma-2" v-on:click="showLastWeek">Last Week</v-btn>
      </v-col>
      <v-col class="shrink">
        <v-btn small class="ma-2" v-on:click="showLastMonth">Last Month</v-btn>
      </v-col>
    </v-row>
    <v-container style="position: relative" fluid>
      <LineBackend ref="line" :chart-data="datacollection" :options="options">
      </LineBackend>
      <v-row
        v-if="showNoDataChip"
        style="position: absolute; top: 0; left: 0; height:100%; width: 100%"
        align="center"
        justify="center"
      >
        <v-col>
          <v-chip x-large class="error"
            >No Data Available for this Timespan</v-chip
          >
        </v-col>
      </v-row>
    </v-container>

    <v-row>
      <v-col v-if="enablePanAndZoom">
        <v-btn v-on:click="resetZoom">Reset Zoom</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
const crypto = require("crypto");

// eslint-disable-next-line no-unused-vars
import * as zoom from "chartjs-plugin-zoom";
import { Line, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

const LineBackend = {
  extends: Line,
  mixins: [reactiveProp],
  props: ["options"],
  methods: {
    resetZoom() {
      this.$data._chart.resetZoom();
    },
    update() {
      this.$data._chart.update();
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  }
};

export default {
  components: { LineBackend: LineBackend },
  name: "LineChart",

  computed: {
    showNoDataChip() {
      if (this.datacollection && this.datacollection.datasets)
        for (const dataset of this.datacollection.datasets) {
          if (dataset.data && dataset.data.length > 0) return false;
        }

      return true;
    },
    options() {
      const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          zoom: {
            pan: {
              enabled: false,
              mode: "x"
            },
            zoom: {
              enabled: false,
              mode: "x"
            }
          }
        },
        scales: {
          xAxes: [
            {
              type: "time",
              time: {
                //min: this.start,

                tooltipFormat: "LT",
                parser: "YYYY-MM-DDTHH:mm:ssZZZZ",
                displayFormats: {
                  hour: "DD.MM. HH:mm",
                  minute: "HH:mm",
                  day: "DD.MM."
                }
              },
              scaleLabel: {
                display: true,
                labelString: "Timestamp"
              },
              ticks: {
                //min: this.start,
                max: this.end
                //min: this.start,
                //max: Date.end
                //source: "auto",
                //min: this.xStart,
                //max: Date.now()
              }
            }
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "value"
              }
            }
          ]
        }
      };
      if (this.enablePanAndZoom === true) {
        options.plugins.zoom.pan.enabled = true;
        options.plugins.zoom.zoom.enabled = true;
      }
      return options;
    }
  },

  data() {
    return {
      datasetBuilders: {},
      datacollection: {},
      xStart: Date.now() - 1000 * 60 * 60,
      end: Date.now(),
      start: Date.now() - 1000 * 60 * 60,
      groupBy: "1m"
    };
  },

  props: {
    enablePanAndZoom: {
      type: Boolean,
      default: true
    }
  },

  mounted() {
    this.fillData();
    this.showLastHour();
  },

  methods: {
    async showLastHour() {
      this.end = Date.now();
      this.start = Date.now() - 1000 * 60 * 60;
      this.groupBy = "1m";
      this.xStart = this.start;

      Object.keys(this.datasetBuilders).forEach(key => {
        this.refreshDataset(key);
      });
    },
    async showLastDay() {
      this.end = Date.now();
      this.start = Date.now() - 1000 * 60 * 60 * 24;
      this.groupBy = "10m";
      this.xStart = this.start;

      Object.keys(this.datasetBuilders).forEach(key => {
        this.refreshDataset(key);
      });
    },
    async showLastWeek() {
      this.end = Date.now();
      this.start = Date.now() - 1000 * 60 * 60 * 24 * 7;
      this.groupBy = "1h";
      this.xStart = this.start;

      Object.keys(this.datasetBuilders).forEach(key => {
        this.refreshDataset(key);
      });
    },
    async showLastMonth() {
      this.end = Date.now();
      this.start = Date.now() - 1000 * 60 * 60 * 24 * 7 * 4;
      this.groupBy = "6h";
      this.xStart = this.start;

      Object.keys(this.datasetBuilders).forEach(key => {
        this.refreshDataset(key);
      });
    },

    resetZoom() {
      this.$refs.line.resetZoom();
    },

    addNewDataset(payload) {
      const dataset = {};
      const datasetBuilder = {};
      if (payload.label) dataset.label = payload.label;
      else dataset.label = "Dataset " + (Object.keys(this.datasets).length + 1);

      if (payload.borderColor) dataset.borderColor = payload.borderColor;
      else dataset.borderColor = "#000000";

      if (payload.fill) dataset.fill = payload.fill;
      else dataset.fill = false;

      if (payload.static === true) {
        datasetBuilder.static = true;
        datasetBuilder.staticData = payload.staticData;
      } else {
        datasetBuilder.static = false;
        datasetBuilder.metric = payload.metric;
        datasetBuilder.cameraId = payload.cameraId;
        dataset.data = [];
      }
      dataset.tension = 0.4
      datasetBuilder.id = crypto.randomBytes(16).toString("hex");
      dataset.builderId = datasetBuilder.id;

      this.datasetBuilders[datasetBuilder.id] = datasetBuilder;
      this.datacollection.datasets.push(dataset);
      this.refreshDataset(datasetBuilder.id);
      return datasetBuilder.id;
    },
    async refreshDataset(id) {
      const builder = this.datasetBuilders[id];
      if (builder.static === true) return;
      let i = 0;
      for (const dataset of this.datacollection.datasets) {
        if (dataset.builderId === id) break;
        i = i + 1;
      }
      this.datacollection.datasets[i].data = await this.$store.dispatch(
        "data/fetchData",
        {
          metric: builder.metric,
          groupBy: this.groupBy,
          cameraId: builder.cameraId,
          start: this.start,
          end: this.end
        }
      );
      this.$refs.line.update();
    },

    fillData() {
      this.datacollection = {
        datasets: []
      };
    }
  }
};
</script>
