const state = {
  mockedActivityData: []
};

const getters = {
  getMockedActivityData: state => filter => {
    return state.mockedActivityData.filter(entry => {
      return entry.x > filter.start && entry.x < filter.end;
    });
  }
};
const mutations = {
  ["ADD_MOCKED_ACTIVITY_DATA"](state, data) {
    state.mockedActivityData.push(data);
  },
  ["RESET_MOCKED_ACTIVITY_DATA"](state) {
    state.mockedActivityData = [];
  }
};
const actions = {
  async fetchActivityData({ dispatch, rootGetters }, options) {
    return await dispatch(
      "backend/sendAuthedRequest",
      {
        path: "/api/getData",
        barnId: rootGetters["backend/getSelectedBarn"]._id,
        payload: {
          _id: options.cameraId,
          start: options.start,
          end: options.end,
          measurement: "activity",
          mutateXY: true,
          groupBy: options.groupBy
        }
      },
      { root: true }
    ).then(response => {
      return response.json().then(data => {
        return data;
      });
    });
  },

  async fetchData({ dispatch, rootGetters }, options) {
    return await dispatch(
      "backend/sendAuthedRequest",
      {
        path: "/api/getData",
        barnId: rootGetters["backend/getSelectedBarn"]._id,
        payload: {
          _id: options.cameraId,
          start: options.start,
          end: options.end,
          measurement: options.metric,
          mutateXY: true,
          groupBy: options.groupBy
        }
      },
      { root: true }
    ).then(response => {
      return response.json().then(data => {
        return data;
      });
    });
  },
  generateMockedActivityData({ commit }) {
    commit("RESET_MOCKED_ACTIVITY_DATA");
    const baseTimestamp = Date.now();
    for (let i = 0; i <= 50000; i = i + 1) {
      commit("ADD_MOCKED_ACTIVITY_DATA", {
        x: baseTimestamp - i * 60000,
        y: Math.random()
      });
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
