<template>
  <v-container fill-height>
    <v-layout align-center justify-space-around>
      <v-flex xs12>
        <v-card>
          <v-card-title>Select your barn</v-card-title>
          <v-card-text>
            <v-card
              class="my-2"
              elevation="8"
              style="border-radius: 16px"
              v-for="barn in barns"
              v-bind:key="barn._id"
            >
              <v-card-title>
                <v-row dense no-gutters>
                  <v-col class="grow">
                    <v-layout justify-start> {{ barn.name }} </v-layout>
                  </v-col>
                  <v-col
                    class="shrink"
                    v-if="
                      $store.getters['backend/getSelectedBarn'] &&
                        $store.getters['backend/getSelectedBarn']._id ===
                          barn._id
                    "
                  >
                    <v-chip small>Selected</v-chip>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-dialog v-model="editBarnDialog" max-width="600px">
                    <template v-slot:activator="{ on }">
                      <v-col>
                        <v-btn
                          v-on:click="editBarn(barn)"
                          v-on="on"
                          color="primary"
                          rounded
                        >
                          <v-icon class="mr-2">edit</v-icon>
                          Edit
                        </v-btn>
                      </v-col>
                    </template>
                    <v-card v-if="barnToEdit">
                      <v-card-title>Edit Barn</v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row dense>
                            <v-col cols="12">
                              <v-text-field
                                outlined
                                label="Name of Barn"
                                v-model="barnToEdit.name"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <v-switch
                                label="Show Discovery Details"
                                v-model="showDiscoveryDetails"
                              ></v-switch>
                            </v-col>
                            <v-col cols="12" v-if="showDiscoveryDetails">
                              <qrcode
                                :value="barnToEdit.discoveryString"
                              ></qrcode>
                            </v-col>

                            <v-col cols="8" v-if="showDiscoveryDetails">
                              <v-text-field
                                outlined
                                readonly
                                v-model="barnToEdit.discoveryString"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" v-if="showDiscoveryDetails">
                              <v-btn
                                class="my-3"
                                outlined
                                rounded
                                v-on:click="copyDiscoveryString"
                                >Copy</v-btn
                              ></v-col
                            >

                            <v-dialog
                              v-model="deleteBarnDialog"
                              max-width="500px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-col cols="12">
                                  <v-btn v-on="on" rounded class="error"
                                    >Delete Barn</v-btn
                                  >
                                </v-col>
                              </template>

                              <v-card>
                                <v-card-title>Delete Barn?</v-card-title>
                                <v-card-text
                                  ><v-alert type="warning" prominent>
                                    This will remove the barn from the App!
                                  </v-alert></v-card-text
                                >
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="primary"
                                    text
                                    v-on:click="deleteBarnDialog = false"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    color="error"
                                    text
                                    v-on:click="deleteBarn"
                                  >
                                    Confirm
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions
                        ><v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          v-on:click="editBarnDialog = false"
                        >
                          Close
                        </v-btn>
                        <v-btn
                          color="primary "
                          text
                          v-on:click="saveBarnChanges"
                        >
                          Save
                        </v-btn></v-card-actions
                      >
                    </v-card>
                  </v-dialog>
                  <v-col
                    v-if="
                      $store.getters['backend/getRole'](barn._id) === 'admin' ||
                        $store.getters['backend/getRole'](barn._id) ===
                          'owner' ||
                        $store.getters['backend/getRole'](barn._id) ===
                          'manager'
                    "
                    ><v-btn
                      rounded
                      class="primary"
                      v-on:click="goToPeopleManagement(barn._id)"
                    >
                      <v-icon class="mr-2">people</v-icon>
                      People</v-btn
                    ></v-col
                  >
                  <v-col>
                    <v-dialog
                      v-if="
                        $store.getters['backend/isLoggedInIntoBarn'](barn._id)
                      "
                      v-model="showLogoutDialog"
                      width="500"
                      :persistent="logoutLoading"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" rounded class="error">
                          <v-icon class="ma-2">exit_to_app</v-icon> Logout
                        </v-btn>
                      </template>

                      <v-card
                        :loading="logoutLoading"
                        :disabled="logoutLoading"
                      >
                        <v-card-title
                          >Log out of
                          {{
                            $store.getters["backend/getSelectedBarn"].name
                          }}?</v-card-title
                        >
                        <v-card-text>
                          Are you sure you want to log out of this barn? You can
                          return at any time.
                        </v-card-text>
                        <v-card-actions>
                          <v-btn text v-on:click="showLogoutDialog = false"
                            >Cancel</v-btn
                          >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            class="primary"
                            v-on:click="startLogout(barn._id)"
                            >Confirm
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-btn
                      v-else
                      v-on:click="goToSignIn(barn._id)"
                      rounded
                      class="success"
                    >
                      <v-icon class="mr-2">person</v-icon>
                      Login
                    </v-btn>
                  </v-col>
                </v-row>
                <v-layout justify-end class="mt-4">
                  <v-chip
                    outlined
                    v-if="$store.getters['backend/isBarnOnline'](barn._id)"
                    color="success"
                    v-on:click="pokeBarn(barn._id)"
                  >
                    <p class="ma-1">Online</p>
                    <span class="onlineDot ma-1"></span>
                  </v-chip>

                  <v-chip
                    outlined
                    v-else
                    color="error"
                    v-on:click="pokeBarn(barn._id)"
                  >
                    <p class="ma-1">Offline</p>
                    <span class="offlineDot ma-1"></span>
                  </v-chip>
                </v-layout>
              </v-card-text>

              <v-card-actions
                v-if="
                  !$store.getters['backend/getSelectedBarn'] ||
                    $store.getters['backend/getSelectedBarn']._id !== barn._id
                "
              >
                <v-btn
                  block
                  rounded
                  v-on:click="$store.dispatch('backend/selectBarn', barn._id)"
                >
                  Select this barn
                </v-btn>
              </v-card-actions>
            </v-card>

            <v-btn
              v-if="barns.length === 0"
              rounded
              color="primary"
              v-on:click="addBarnDialog = true"
              >Add a Barn
            </v-btn>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog v-model="addBarnDialog" persistent max-width="600px">
      <template v-slot:activator="{ on }">
        <v-btn fab color="primary" fixed right bottom v-on="on">
          <v-icon>add</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title>Add new Barn</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="Name of Barn"
                  v-model="newName"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-textarea
                  outlined
                  label="Discovery-String"
                  v-model="newDiscovery"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-dialog v-model="qrDialog" max-width="600px">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on">
                  Open QR Reader
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <qrcode-stream
                    v-if="showStream"
                    @decode="onDecode"
                    @init="onInit"
                  ></qrcode-stream>

                  <v-container v-else>
                    <qrcode-drop-zone @decode="onDecode">
                      <v-card
                        elevation="10"
                        style="border-radius: 0;"
                        class="ma-4"
                      >
                        <v-card-text>Drop Image here</v-card-text>
                      </v-card>
                      <p>or</p>
                    </qrcode-drop-zone>

                    <qrcode-capture
                      :multiple="false"
                      @decode="onDecode"
                    ></qrcode-capture>
                  </v-container>
                </v-card-text>
                <v-alert :type="scanAlertType" v-if="showScanAlert">{{
                  scanAlertText
                }}</v-alert>

                <v-card-actions
                  ><v-spacer></v-spacer>
                  <v-btn color="primary" text v-on:click="qrDialog = false">
                    Close
                  </v-btn>
                  <v-btn color="primary " text v-on:click="addQrCode">
                    Save
                  </v-btn></v-card-actions
                >
              </v-card>
            </v-dialog>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text v-on:click="closeDialog">
            Close
          </v-btn>
          <v-btn color="primary " text v-on:click="saveNewBarn">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const {
  QrcodeStream,
  QrcodeDropZone,
  QrcodeCapture
} = require("vue-qrcode-reader");
//const { VueQrcode } = require("@chenfengyuan/vue-qrcode");

export default {
  name: "Barns",
  components: { QrcodeStream, QrcodeDropZone, QrcodeCapture },
  data() {
    return {
      showLogoutDialog: false,
      logoutLoading: false,
      deleteBarnDialog: false,
      showDiscoveryDetails: false,
      barnToEdit: null,
      editBarnDialog: false,
      addBarnDialog: false,
      qrDialog: false,
      newName: null,
      newDiscovery: null,
      showStream: true,
      showInitProgress: false,
      scannedQrCode: null,
      scanAlertType: "success",
      scanAlertText: "",
      showScanAlert: false
    };
  },

  computed: {
    barns() {
      return Object.values(this.$store.getters["backend/getBarns"]);
    }
  },
  methods: {
    async goToSignIn(id) {
      await this.$store.dispatch("backend/selectBarn", id);
      this.$router.push("/signin");
    },
    checkValidity(input) {
      if (Buffer.from(input, "base64").toString("base64") !== input)
        return false;

      const buffer = new Buffer(input, "base64");
      try {
        const discoveryObject = JSON.parse(buffer.toString("ascii"));

        if (
          !discoveryObject.local ||
          !discoveryObject.local.hostname ||
          !discoveryObject.local.protocol ||
          !discoveryObject.local.port ||
          !discoveryObject.remote ||
          !discoveryObject.remote.hostname ||
          !discoveryObject.remote.protocol ||
          !discoveryObject.remote.port
        )
          return false;
      } catch {
        return false;
      }
      return true;
    },
    copyDiscoveryString() {
      navigator.clipboard.writeText(this.barnToEdit.discoveryString);
    },
    saveBarnChanges() {
      this.deleteBarnDialog = false;
      this.$store.dispatch("backend/updateBarn", this.barnToEdit);
      this.barnToEdit = null;
      this.editBarnDialog = false;
    },
    deleteBarn() {
      this.deleteBarnDialog = false;
      this.editBarnDialog = false;
      this.$store.dispatch("backend/deleteBarn", this.barnToEdit);
    },
    editBarn(barn) {
      this.barnToEdit = {
        name: barn.name,
        _id: barn._id,
        discoveryString: this.$store.getters["backend/getDiscoveryString"](
          barn._id
        )
      };
    },
    addQrCode() {
      this.newDiscovery = this.scannedQrCode;
      this.qrDialog = false;
    },
    async onInit(promise) {
      this.showInitProgress = true;
      try {
        await promise;
      } catch (error) {
        this.showInitProgress = false;
        this.showStream = false;
      }
    },
    onDecode(decodedString) {
      if (this.checkValidity(decodedString)) {
        this.scannedQrCode = decodedString;
        this.showSuccessAlert();
      } else this.showErrorAlert();
    },
    showSuccessAlert() {
      this.scanAlertType = "success";
      this.scanAlertText = "Scanned Valid Code!";
      this.showScanAlert = true;
    },
    showErrorAlert() {
      this.scanAlertType = "error";
      this.scanAlertText = "Scanned code is not valid!";
      this.showScanAlert = true;
    },
    closeDialog() {
      this.addBarnDialog = false;
      this.newName = null;
      this.newDiscovery = null;
    },
    saveNewBarn() {
      this.addBarnDialog = false;
      this.$store.dispatch("backend/addNewBarn", {
        name: this.newName,
        discoveryString: this.newDiscovery
      });

      this.newName = null;
      this.newDiscovery = null;
    },
    pokeBarn(id) {
      this.$store.dispatch("backend/poke", id);
    },
    async startLogout(id) {
      await this.$store.dispatch("backend/logOutOfBarn", { id: id });
      this.logoutLoading = true;
      this.showLogoutDialog = false;
      this.logoutLoading = false;
      if (this.$store.getters["backend/getSelectedBarn"]._id === id)
        await this.$router.replace("/signin");
    },
    goToPeopleManagement(id) {
      this.$router.push({ name: "People", params: { barnId: id } });
    }
  },

  mounted() {
    this.$store.dispatch("backend/pokeAllBarns");
  }
};
</script>

<style scoped>
.onlineDot {
  height: 20px;
  width: 20px;
  background-color: #00e700;
  border-radius: 50%;
  display: inline-block;
}

.offlineDot {
  height: 20px;
  width: 20px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
</style>
