<template>
  <v-container fill-height>
    <v-layout align-center justify-space-around>
      <v-flex xs12>
        <v-btn class="my-4" color="primary" block rounded v-on:click="goToBarns"
          >Choose barn</v-btn
        >
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "Settings",
  methods: {
    goToBarns() {
      this.$router.push("/barns");
    },
    chooseMode() {}
  }
};
</script>

<style scoped></style>
