import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user/user.js";

import VuexPersist from "vuex-persist";
import cameras from "@/store/modules/cameras/cameras";
import backend from "@/store/modules/backend/backend";
import data from "@/store/modules/data/data";
Vue.use(Vuex);

let key = "vetvise.pb";
import env from "../env/env";

if (env === "dev") key = key + "_dev";
if (env === "preview") key = key + "_preview";
if (env === "staging") key = key + "_staging";

const vuexLocalStorage = new VuexPersist({
  key: key,
  storage: window.localStorage,
  modules: ["user", "backend", "cameras"]
});

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user: user,
    cameras: cameras,
    backend: backend,
    data: data
  },
  plugins: [vuexLocalStorage.plugin]
});
