const randomString = require("randomstring");

const state = {
  barns: {},
  userLists: {},
  selectedBarn: null,
  tinyPoke: null
};
const getters = {
  getUserListOfBarn: state => id => {
    if (state.userLists[id]) return state.userLists[id];
    return {};
  },
  isBarnOnline: state => id => {
    if (
      state.barns &&
      state.barns[id] &&
      state.barns[id].discoveryObject &&
      ((state.barns[id].discoveryObject.local &&
        state.barns[id].discoveryObject.local.online) ||
        (state.barns[id].discoveryObject.remote &&
          state.barns[id].discoveryObject.remote.online))
    )
      return true;
    return false;
  },
  getBarns: state => {
    return state.barns;
  },
  getRole: state => id => {
    const barn = state.barns[id];
    if (barn && barn.auth && barn.auth.accessToken) {
      let base64Payload = barn.auth.accessToken.split(".")[1];
      let payload = Buffer.from(base64Payload, "base64");

      return JSON.parse(payload.toString()).role;
    }
    return "none";
  },
  getUserId: state => id => {
    return state.barns[id].auth.uid;
  },
  getSelectedBarn: state => {
    return state.barns[state.selectedBarn];
  },
  getBarn: state => id => {
    return state.barns[id];
  },
  isLoggedInIntoBarn: state => id => {
    return (
      state.barns[id] && state.barns[id].auth && state.barns[id].auth.loggedIn
    );
  },
  getLocalUrl: state => id => {
    const barn = state.barns[id];
    if (!barn) return null;
    let discovery = barn.discoveryObject.local;
    if (!discovery) return null;
    let url = new URL("https://vetvise.com");
    url.hostname = discovery.hostname;
    url.port = discovery.port;
    url.protocol = discovery.protocol;
    return url;
  },
  getRemoteUrl: state => id => {
    const barn = state.barns[id];
    if (!barn) return null;
    let discovery = barn.discoveryObject.remote;
    if (!discovery) return null;
    let url = new URL("https://vetvise.com");
    url.hostname = discovery.hostname;
    url.port = discovery.port;
    url.protocol = discovery.protocol;
    return url;
  },
  getBackendUrl: (state, getters) => id => {
    const localUrl = getters["getLocalUrl"](id);
    const remoteUrl = getters["getRemoteUrl"](id);
    const localStatus = getters["getLocalStatus"](id);
    const remoteStatus = getters["getRemoteStatus"](id);

    if (localUrl && localStatus) return localUrl;
    if (remoteUrl && remoteStatus) return remoteUrl;
    return null;
  },
  getSelectedBackendUrl: (state, getters) => {
    return getters["getBackendUrl"](state.selectedBarn);
  },
  getLocalStatus: state => id => {
    return state.barns[id].discoveryObject.local.online;
  },
  getRemoteStatus: state => id => {
    return state.barns[id].discoveryObject.remote.online;
  },
  getRefreshToken: state => id => {
    if (!state.barns[id] || !state.barns[id].auth) return null;
    return state.barns[id].auth.refreshToken;
  },
  getAccessToken: state => id => {
    if (!state.barns[id] || !state.barns[id].auth) return null;
    return state.barns[id].auth.accessToken;
  },
  getSelectedRefreshToken: (state, getters) => {
    return getters["getRefreshToken"](state.selectedBarn);
  },
  getSelectedAccessToken: (state, getters) => {
    return getters["getAccessToken"](state.selectedBarn);
  },
  isLoggedInIntoSelectedBarn: (state, getters) => {
    return (
      getters["getSelectedBarn"] &&
      getters["getSelectedBarn"].auth &&
      getters["getSelectedBarn"].auth.loggedIn
    );
  },
  getDiscoveryString: state => id => {
    const barn = state.barns[id];
    if (!barn) return null;
    if (!barn.discoveryObject) return null;
    const discoveryObject = {
      local: {
        hostname: barn.discoveryObject.local.hostname,
        port: barn.discoveryObject.local.port,
        protocol: barn.discoveryObject.local.protocol
      },
      remote: {
        hostname: barn.discoveryObject.remote.hostname,
        port: barn.discoveryObject.remote.port,
        protocol: barn.discoveryObject.remote.protocol
      }
    };
    return new Buffer(JSON.stringify(discoveryObject)).toString("base64");
  }
};
const mutations = {
  ["RESET_USER_LIST_OF_BARN"](state, data) {
    this._vm.$set(state.userLists, data.id, {});
  },
  ["ADD_USER_TO_USER_LIST_OF_BARN"](state, data) {
    this._vm.$set(state.userLists[data.id], data.user._id, data.user);
  },
  ["UPDATE_BARN"](state, data) {
    this._vm.$set(state.barns[data._id], "name", data.name);
  },
  ["ADD_BARN"](state, data) {
    this._vm.$set(state.barns, data._id, data);
  },
  ["SET_BACKEND_ID"](state, data) {
    this._vm.$set(state.barns[data.id], "backendId", data.backendId);
  },
  ["SET_LOCAL_STATUS"](state, data) {
    this._vm.$set(
      state.barns[data.id].discoveryObject.local,
      "online",
      data.online
    );
  },
  ["SET_REMOTE_STATUS"](state, data) {
    this._vm.$set(
      state.barns[data.id].discoveryObject.remote,
      "online",
      data.online
    );
  },
  ["REMOVE_BARN"](state, id) {
    this._vm.$delete(state.barns, id);
  },
  ["SELECT_BARN"](state, id) {
    state.selectedBarn = id;
  },
  ["SET_TINY_POKE"](state, data) {
    state.tinyPoke = data;
  },
  ["CLEAR_TINY_POKE"](state) {
    state.tinyPoke = null;
  },
  ["LOGIN"](state, data) {
    console.log(data.id);
    console.log(state.barns);
    this._vm.$set(state.barns[data.id], "auth", {
      loggedIn: true,
      refreshToken: data.token,
      uid: data.uid
    });
  },
  ["LOGOUT"](state, data) {
    this._vm.$set(state.barns[data.id], "auth", {
      loggedIn: false,
      refreshToken: null
    });
  },
  ["UNSELECT_BARN"](state) {
    state.selectedBarn = null;
  },
  ["UPDATE_ACCESS_TOKEN"](state, data) {
    this._vm.$set(state.barns[data.id].auth, "accessToken", data.token);
  }
};
const actions = {
  async editRole({ dispatch }, payload) {
    return await dispatch("sendAuthedRequest", {
      path: "/api/editRole",
      barnId: payload.barnId,
      payload: {
        targetUid: payload.uid,
        newRole: payload.newRole
      }
    })
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  },

  async removeUser({ dispatch }, payload) {
    return await dispatch("sendAuthedRequest", {
      path: "/api/removeUser",
      barnId: payload.barnId,
      payload: {
        targetUid: payload.uid
      }
    })
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  },
  async loadUserListOfBarn({ commit, dispatch }, payload) {
    await dispatch("sendAuthedRequest", {
      path: "/api/getUserList",
      barnId: payload.barnId
    }).then(response => {
      response.json().then(users => {
        commit("RESET_USER_LIST_OF_BARN", {
          id: payload.barnId
        });
        users.forEach(user => {
          if (user.role === "none") user.humanReadableRole = "No Permissions";
          if (user.role === "viewer") user.humanReadableRole = "Viewer";
          if (user.role === "owner") user.humanReadableRole = "Owner";
          if (user.role === "admin") user.humanReadableRole = "Administrator";

          let date = new Date(user.creationTimestamp * 1000);
          user.humanReadableCreationTimestamp =
            date.getDate() +
            "." +
            (date.getMonth() + 1) +
            "." +
            date.getFullYear();
          commit("ADD_USER_TO_USER_LIST_OF_BARN", {
            id: payload.barnId,
            user: user
          });
        });
      });
    });
  },
  deleteBarn({ commit, state, dispatch }, barnToDelete) {
    if (state.selectedBarn === barnToDelete._id) {
      dispatch("stopTinyPoke");
      commit("UNSELECT_BARN");
    }
    commit("REMOVE_BARN", barnToDelete._id);
  },
  updateBarn({ commit }, barnToUpdate) {
    commit("UPDATE_BARN", barnToUpdate);
  },
  async poke({ commit, getters }, id) {
    const localUrl = getters["getLocalUrl"](id);
    localUrl.pathname = "/api/poke";

    const remoteUrl = getters["getRemoteUrl"](id);
    remoteUrl.pathname = "/api/poke";
    const promises = [];

    promises.push(
      fetch(localUrl, {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
      })
        .then(response => {
          if (getters["getLocalStatus"](id) !== response.ok) {
            commit("SET_LOCAL_STATUS", { id: id, online: response.ok });
          }

          if (response.ok) return response.json();
        })
        .then(response => {
          if (getters["getBarn"](id).backendId !== response.id)
            commit("SET_BACKEND_ID", { id: id, backendId: response.id });
        })
        .catch(() => {
          if (getters["getLocalStatus"](id) !== false) {
            commit("SET_LOCAL_STATUS", { id: id, online: false });
          }
        })
    );

    promises.push(
      fetch(remoteUrl, {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
      })
        .then(response => {
          if (getters["getRemoteStatus"](id) !== response.ok) {
            commit("SET_REMOTE_STATUS", { id: id, online: response.ok });
          }

          if (response.ok) return response.json();
        })
        .then(response => {
          if (getters["getBarn"](id).backendId !== response.id)
            commit("SET_BACKEND_ID", { id: id, backendId: response.id });
        })
        .catch(() => {
          if (getters["getRemoteStatus"](id) !== false) {
            commit("SET_REMOTE_STATUS", { id: id, online: false });
          }
        })
    );
    await Promise.all(promises);
  },
  async tinyPoke({ commit, getters }, id) {
    const localUrl = getters["getLocalUrl"](id);
    localUrl.pathname = "/api/p";
    const remoteUrl = getters["getRemoteUrl"](id);
    remoteUrl.pathname = "/api/p";
    fetch(localUrl)
      .then(response => {
        if (getters["getLocalStatus"](id) !== response.ok) {
          commit("SET_LOCAL_STATUS", { id: id, online: response.ok });
        }
      })
      .catch(() => {
        if (getters["getLocalStatus"](id) !== false) {
          commit("SET_LOCAL_STATUS", { id: id, online: false });
        }
      });

    fetch(remoteUrl)
      .then(response => {
        if (getters["getRemoteStatus"](id) !== response.ok) {
          commit("SET_REMOTE_STATUS", { id: id, online: response.ok });
        }
      })
      .catch(() => {
        if (getters["getRemoteStatus"](id) !== false) {
          commit("SET_REMOTE_STATUS", { id: id, online: false });
        }
      });
  },
  addNewBarn({ commit, state, dispatch }, { name, discoveryString }) {
    const buffer = new Buffer(discoveryString, "base64");
    const discoveryObject = JSON.parse(buffer.toString("ascii"));
    const newId = randomString.generate(20);
    commit("ADD_BARN", {
      name: name ? name : "Unnamed Barn",
      discoveryObject: discoveryObject,
      _id: newId
    });
    if (Object.keys(state.barns).length === 1) dispatch("selectBarn", newId);
    dispatch("poke", newId);
  },
  startTinyPoke({ commit, dispatch, getters }) {
    const id = getters["getSelectedBarn"]._id;
    const tinyPoke = setInterval(() => {
      dispatch("tinyPoke", id);
    }, 60000);
    commit("SET_TINY_POKE", tinyPoke);
  },
  stopTinyPoke({ commit, state }) {
    clearTimeout(state.tinyPoke);
    commit("CLEAR_TINY_POKE");
  },
  async pokeAllBarns({ state, dispatch }) {
    const promises = [];
    Object.keys(state.barns).forEach(id => {
      promises.push(dispatch("poke", id));
    });
    await Promise.all(promises);
  },
  async selectBarn({ commit, dispatch }, id) {
    dispatch("stopTinyPoke");
    commit("SELECT_BARN", id);
    dispatch("startTinyPoke");
  },
  restartTinyPoke({ getters, dispatch }) {
    if (getters["getSelectedBarn"]) {
      dispatch("stopTinyPoke");
      dispatch("startTinyPoke");
    }
  },
  async registerAtBarn({ state, dispatch, getters }, payload) {
    if (
      state.barns[payload.id] &&
      state.barns[payload.id].auth &&
      state.barns[payload.id].auth.loggedIn
    )
      await dispatch("logOutOfBarn", { id: payload.id });
    let url = getters["getBackendUrl"](payload.id);
    url.pathname = "/api/register";
    await fetch(url, {
      body: JSON.stringify({
        username: payload.username,
        password: payload.password,
        passwordRepetition: payload.passwordRepetition
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
      },
      method: "POST"
    })
      .then(res => {
        if (res.status !== 200) {
          console.log(res.status, "->", res.statusText);
          return false;
        }
      })
      .catch(err => {
        console.log(err);
        return false;
      });
    return true;
  },
  async signInAtBarn({ commit, getters }, payload) {
    let url = getters["getBackendUrl"](payload.id);
    url.pathname = "/api/login";
    return await fetch(url, {
      body: JSON.stringify({
        username: payload.username,
        password: payload.password
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
      },
      method: "POST"
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          console.log(response.status, "->", response.statusText);
          throw new Error(response.statusText);
        }
      })
      .then(res => {
        let base64Payload = res.token.split(".")[1];
        commit("LOGIN", {
          token: res.token,
          id: payload.id,
          uid: JSON.parse(Buffer.from(base64Payload, "base64").toString()).uid
        });
        return true;
      })
      .catch(status => {
        console.log(status);
        return false;
      });
    },
  async logOutOfBarn({ commit, dispatch, getters }, payload) {
    await dispatch("sendAuthedRequest", {
      path: "/api/logout",
      barnId: payload.id,
      payload: {
        refreshToken: getters["getRefreshToken"](payload.id)
      }
    });

    commit("LOGOUT", { id: payload.id });
  },
  async sendAuthedRequest(
    { getters, dispatch },
    { path, barnId, payload, urlOverride, withRefreshToken }
  ) {
    if (withRefreshToken)
      await dispatch("fetchNewAccessToken", { barnId: barnId });
    let url;
    if (urlOverride) url = urlOverride;
    else {
      url = getters["getBackendUrl"](barnId);
      url.pathname = path;
    }
    if (!payload) payload = {};
    payload.accessToken = getters["getAccessToken"](barnId);

    return await fetch(url, {
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
      },
      method: "POST"
    }).then(response => {
      if (response.ok) {
        return response;
      } else {
        if (response.status === 403 && !withRefreshToken)
          return dispatch("sendAuthedRequest", {
            urlOverride: url,
            payload: payload,
            withRefreshToken: true,
            barnId: barnId
          });
        else {
          console.log(response.status, "->", response.statusText);
          throw new Error(response.statusText);
        }
      }
    });
  },
  async fetchNewAccessToken({ getters, commit }, { barnId }) {
    let url = getters["getBackendUrl"](barnId);
    url.pathname = "/api/refreshAccessToken";

    return await fetch(url, {
      body: JSON.stringify({
        refreshToken: getters["getRefreshToken"](barnId)
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
      },
      method: "POST"
    }).then(response => {
      if (response.ok) {
        return response.json().then(result => {
          commit("UPDATE_ACCESS_TOKEN", { id: barnId, token: result.token });
        });
      }
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
