import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import vuetify from "./plugins/vuetify";
import VueQrcodeReader from "vue-qrcode-reader";
import VueQrCode from "@chenfengyuan/vue-qrcode";
//if (!window.location.toString().startsWith("http://ws.pig-brother.com:8080"))
//  window.location = "http://ws.pig-brother.com:8080";
Vue.config.productionTip = false;
Vue.component(VueQrCode.name, VueQrCode);
new Vue({
  router,
  store,
  vuetify,
  VueQrcodeReader,
  render: h => h(App)
}).$mount("#app");
