<template>
  <v-container fill-height>
    <v-layout align-center justify-space-around>
      <v-flex xs12>
        <v-card>
          <v-card-title>
            Movement-Activity
          </v-card-title>
          <v-card-text>
            <LineChart
              ref="movementActivityLineChart"
              :enablePanAndZoom="false"
            ></LineChart>
          </v-card-text>
        </v-card>
      </v-flex></v-layout
  ></v-container>
</template>

<script>
import LineChart from "@/components/Charts/LineChart";
export default {
  name: "Data",
  components: { LineChart },
  data() {
    return {
      cameraId: this.$route.params.cameraId
    };
  },
  mounted() {
    this.$refs["movementActivityLineChart"].addNewDataset({
      label: "Movement-Activity",
      borderColor: "#176106",
      cameraId: this.cameraId,
      metric: "activity"
    });
  }
};
</script>

<style scoped></style>
