<template>
  <v-container fill-height>
    <v-layout align-center justify-space-around>
      <v-flex xs12 sm4 md8>
        <v-card :loading="showLoading" :disabled="showLoading">
          <v-card-title>
            Sign in to {{ $store.getters["backend/getSelectedBarn"].name }}
          </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-text-field
                solo
                label="Username"
                v-model="username"
                :rules="usernameRules"
              >
              </v-text-field>
              <v-text-field
                solo
                label="Password"
                v-model="password"
                :rules="passwordRules"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                v-on:click:append="showPassword = !showPassword"
              >
              </v-text-field>
            </v-form>
            <v-alert v-if="show_alert" :type="alert_type">{{
              alert_text
            }}</v-alert>
          </v-card-text>
          <v-card-actions>
            <v-btn v-on:click="goToSignup" class="ma-4" text>
              Register
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn v-on:click="validateAndSubmit" right color="primary">
              Sign In
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "SignIn",
  data() {
    return {
      show_alert: false,
      alert_type: "success",
      alert_text: "",
      showLoading: false,
      showPassword: false,
      username: null,
      password: null,
      valid: null,
      usernameRules: [v => !!v || "Username cannot be empty!"],
      passwordRules: [v => !!v || "Password cannot be empty!"]
    };
  },
  methods: {
    async validateAndSubmit() {
      if (!this.$refs.form.validate()) return;
      this.show_alert = false;
      this.showLoading = true;
      const result = await this.$store.dispatch("backend/signInAtBarn", {
        username: this.username,
        password: this.password,
        id: this.$store.getters["backend/getSelectedBarn"]._id
      });
      this.showLoading = false;

      if (result) {
        this.$router.push("/");
      } else {
        this.password = "";
        this.alert_type = "error";
        this.alert_text = "Could not Sign In.";
        this.show_alert = true;
      }
    },
    resetPassword() {},
    goToSignup() {
      this.$router.push({ name: "SignUp" });
    }
  }
};
</script>

<style scoped>
#content_card {
  padding: 16px;
}
</style>
