<template>
  <v-container>
    <v-row dense no-gutters align="center">
      <v-col>
        <v-row dense no-gutters>
          <v-col cols="12">
            <h3 class="ma-2">{{ user.username }}</h3>
          </v-col>
          <v-col
            ><v-chip :class="roleClasses" style="font-weight: bolder">{{
              user.humanReadableRole
            }}</v-chip></v-col
          >
          <v-col>{{ user.humanReadableCreationTimestamp }}</v-col>

          <v-col
            class="shrink"
            v-if="user._id === $store.getters['backend/getUserId'](barn_id)"
            ><v-chip>You</v-chip></v-col
          >
        </v-row>
      </v-col>
      <v-col cols="12" v-if="showEdit">
        <v-row class="mt-4">
          <v-dialog
            v-model="editRoleDialog"
            :persistent="editRoleLoading"
            max-width="600px"
          >
            <template v-slot:activator="{ on }">
              <v-col>
                <v-btn
                  rounded
                  outlined
                  class="primary--text"
                  small
                  v-on="on"
                  v-on:click="newRole = user.role"
                >
                  <v-icon class="mr-2">
                    edit
                  </v-icon>
                  Edit Role
                </v-btn>
              </v-col>
            </template>
            <v-card :loading="editRoleLoading" :deactivated="editRoleLoading">
              <v-card-title> Change Role of {{ user.username }} </v-card-title>
              <v-card-text>
                <v-row align="center" justify="center">
                  <v-col>
                    <v-card outlined shaped>
                      <v-card-title>Current Role</v-card-title>
                      <v-card-text>
                        <v-chip
                          :class="roleClasses"
                          style="font-weight: bolder"
                          >{{ user.humanReadableRole }}</v-chip
                        >
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12">
                    <v-icon large>arrow_downwards</v-icon>
                  </v-col>
                  <v-col>
                    <v-card outlined shaped>
                      <v-card-title>New Role</v-card-title>
                      <v-card-text>
                        <v-select
                          :items="availableRoles"
                          v-model="newRole"
                          item-text="humanReadable"
                          item-value="id"
                        >
                        </v-select>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-alert v-if="showRoleEditAlert" type="error">
                Could not edit Role!
              </v-alert>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text v-on:click="editRoleDialog = false">
                  Cancel
                </v-btn>
                <v-btn color="primary" text v-on:click="saveRole">
                  Save New Role
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
            v-model="removeDialog"
            :persistent="removeUserLoading"
            max-width="500px"
          >
            <template v-slot:activator="{ on }">
              <v-col>
                <v-btn rounded outlined class="error--text" small v-on="on">
                  <v-icon class="mr-2">
                    delete
                  </v-icon>
                  Remove User
                </v-btn>
              </v-col>
            </template>
            <v-card
              :loading="removeUserLoading"
              :deactivated="removeUserLoading"
            >
              <v-card-title>
                Remove User from Barn?
              </v-card-title>
              <v-card-text>
                This will remove the user from the barn, rendering them unable
                to log in or view any resources.
              </v-card-text>
              <v-alert v-if="showRemoveUserAlert" type="error">
                Could not remove user!
              </v-alert>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text v-on:click="removeDialog = false">
                  Cancel
                </v-btn>
                <v-btn color="error" text v-on:click="removeUser">
                  Confirm
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-col>
    </v-row>

    <v-divider class="my-2"></v-divider>
  </v-container>
</template>

<script>
export default {
  name: "User",
  data() {
    return {
      removeDialog: false,
      showRemoveUserAlert: false,
      showRoleEditAlert: false,
      removeUserLoading: false,
      editRoleLoading: false,
      editRoleDialog: false,
      newRole: null
    };
  },
  props: ["user", "barn_id"],
  computed: {
    availableRoles() {
      const ownRole = this.$store.getters["backend/getRole"](this.barn_id);
      const roles = [];

      if (ownRole === "admin") {
        roles.push({ id: "none", humanReadable: "No Permissions" });
        roles.push({ id: "viewer", humanReadable: "Viewer" });
        roles.push({ id: "owner", humanReadable: "Owner" });
        roles.push({ id: "admin", humanReadable: "Administrator" });
      }
      if (ownRole === "owner") {
        if (this.user.role !== "admin" && this.user.role !== "owner") {
          roles.push({ id: "none", humanReadable: "No Permissions" });
          roles.push({ id: "viewer", humanReadable: "Viewer" });
        }
      }
      return roles;
    },
    roleClasses() {
      const classes = [];
      if (this.user.role === "viewer") classes.push("success");
      if (this.user.role === "owner") classes.push("info");
      if (this.user.role === "admin") classes.push("error");
      return classes;
    },

    showEdit() {
      if (
        this.user._id === this.$store.getters["backend/getUserId"](this.barn_id)
      )
        return false;

      if (
        this.$store.getters["backend/getRole"](this.barn_id) === "owner" &&
        (this.user.role === "none" || this.user.role === "viewer")
      )
        return true;

      if (this.$store.getters["backend/getRole"](this.barn_id) === "admin")
        return true;

      return false;
    }
  },
  methods: {
    async saveRole() {
      this.showRoleEditAlert = false;
      this.editRoleLoading = true;
      const result = await this.$store.dispatch("backend/editRole", {
        barnId: this.barn_id,
        uid: this.user._id,
        newRole: this.newRole
      });
      this.editRoleLoading = false;
      if (result) {
        this.editRoleDialog = false;
        this.$emit("reload_list");
      } else {
        this.showRoleEditAlert = true;
      }
    },
    async removeUser() {
      this.showRemoveUserAlert = false;
      this.removeUserLoading = true;
      const result = await this.$store.dispatch("backend/removeUser", {
        barnId: this.barn_id,
        uid: this.user._id
      });
      this.removeUserLoading = false;
      if (result) {
        this.removeDialog = false;
        this.$emit("reload_list");
      } else {
        this.showRemoveUserAlert = true;
      }
    }
  }
};
</script>

<style scoped></style>
