<template>
  <v-container fill-height>
    <v-layout align-center justify-space-around>
      <v-flex xs12>
        <v-card>
          <v-card-title>Create a New Camera</v-card-title>

          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  label="Name"
                  outlined
                  v-model="cameraName"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Username"
                  outlined
                  v-model="username"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Password"
                  outlined
                  v-model="password"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="IP-Address"
                  outlined
                  v-model="ip"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Port"
                  type="number"
                  outlined
                  v-model="port"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Path"
                  outlined
                  v-model="path"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-switch
                  label="Allow Live-Streaming?"
                  v-model="allowLiveStreaming"
                ></v-switch>
              </v-col>
            </v-row>

            <v-alert v-if="alert.enabled" :type="alert.type">{{
              alert.text
            }}</v-alert>
          </v-card-text>
          <v-card-actions>
            <v-btn rounded class="primary" block v-on:click="saveCamera"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-flex></v-layout
    ></v-container
  >
</template>

<script>
export default {
  name: "CreateCamera",
  data() {
    return {
      cameraName: "",
      ip: "",
      port: "554",
      username: "admin",
      password: "12345",
      path: "/live/main",
      allowLiveStreaming: true,
      loading: false,
      alert: {
        type: "success",
        enabled: false,
        text: ""
      }
    };
  },
  methods: {
    async saveCamera() {
      this.alert.enabled = true;

      const cameraRecord = {};
      cameraRecord.name = this.cameraName;
      cameraRecord.address = this.ip;
      cameraRecord.rtsp =
        "rtsp://" +
        this.username +
        ":" +
        this.password +
        "@" +
        this.ip +
        ":" +
        this.port +
        this.path;
      cameraRecord.streamingEnabled = this.allowLiveStreaming;

      this.loading = true;
      const res = await this.$store.dispatch(
        "cameras/createNewCamera",
        cameraRecord
      );
      this.loading = false;
      if (res === true) {
        this.alert.type = "success";
        this.alert.text = "Camera was successfully created.";
        this.alert.enabled = true;
      } else {
        this.alert.type = "error";
        this.alert.type = "Camera could not be created";
        this.alert.enabled = true;
      }
    }
  }
};
</script>

<style scoped></style>
