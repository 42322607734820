const state = {
  streamableCameras: {},
  stillFrames: {}
};

const getters = {
  getStreamableCameras: state => {
    return state.streamableCameras;
  },
  getStillFrame: state => id => {
    return state.stillFrames[id];
  },
  getStillFrames: state => {
    return state.stillFrames;
  },
  getCamera: state => id => {
    return state.streamableCameras[id];
  }
};
const mutations = {
  ["ADD_STREAMABLE_CAMERA"](state, data) {
    state.streamableCameras = {
      ...state.streamableCameras,
      [data._id]: { ...data }
    };

    // this._vm.$set(state.streamableCameras, data._id, data);
  },
  ["RESET_STREAMABLE_CAMERAS"](state) {
    state.streamableCameras = {};
  },
  ["SET_STILL_FRAME"](state, data) {
    state.stillFrames = {
      ...state.stillFrames,
      [data.cameraId]: { ...{ timestamp: Date.now(), frame: data.stillFrame } }
    };
  }
};
const actions = {
  async reloadThumbnails({ getters, dispatch }) {
    const promises = [];
    Object.keys(getters["getStreamableCameras"]).forEach(key => {
      promises.push(dispatch("loadThumbnail", { cameraId: key }));
    });
    await Promise.all(promises);
  },
  async loadThumbnail({ commit, rootGetters, getters, dispatch }, payload) {
    const cameraRecord = getters["getCamera"](payload.cameraId);
    const stillFrame = getters["getStillFrame"](payload.cameraId);
    if (!cameraRecord) {
      return;
    }
    if (!payload.force)
      if (stillFrame && Date.now() - stillFrame.timestamp <= 10 * 1000) {
        return;
      }
    dispatch(
      "backend/sendAuthedRequest",
      {
        path: "/api/camera/getStillFrame",
        barnId: rootGetters["backend/getSelectedBarn"]._id,
        payload: { _id: payload.cameraId }
      },
      { root: true }
    ).then(response => {
      response.blob().then(blob => {
        commit("SET_STILL_FRAME", {
          cameraId: payload.cameraId,
          stillFrame: URL.createObjectURL(blob)
        });
      });
    });
  },
  async loadCameras({ commit, dispatch, rootGetters }) {
    commit("RESET_STREAMABLE_CAMERAS");
    await dispatch(
      "backend/sendAuthedRequest",
      {
        path: "/api/camera/get-streamable",
        barnId: rootGetters["backend/getSelectedBarn"]._id
      },
      { root: true }
    ).then(response => {
      response.json().then(cameras => {
        Object.values(cameras).forEach(camera => {
          commit("ADD_STREAMABLE_CAMERA", camera);
          dispatch("loadThumbnail", { cameraId: camera._id });
        });
      });
    });
  },
  async createNewCamera({ dispatch, rootGetters }, cameraRecord) {
    return await dispatch(
      "backend/sendAuthedRequest",
      {
        path: "/api/camera/add",
        barnId: rootGetters["backend/getSelectedBarn"]._id,
        payload: {
          cameraRecord: {
            name: cameraRecord.name,
            rtsp: cameraRecord.rtsp,
            address: cameraRecord.address,
            streamingEnabled: cameraRecord.streamingEnabled
          }
        }
      },
      { root: true }
    ).then(response => {
      return !!response.ok;
    });
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
