<template>
  <v-app>
    <v-navigation-drawer
      v-model="showNavigationDrawer"
      temporary
      fixed
      v-if="navigationDrawerEnabled"
    >
      <v-list nav>
        <v-list-item link v-on:click="goToDashboard">
          <v-list-item-icon>
            <v-icon>home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-spacer></v-spacer>

        <v-list-item link v-on:click="goToSettings">
          <v-list-item-icon>
            <v-icon>settings</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-spacer></v-spacer>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-btn v-if="showBackArrow" icon v-on:click="goBack">
        <v-icon>arrow_back</v-icon>
      </v-btn>

      <v-app-bar-nav-icon
        v-on:click="showNavigationDrawer = !showNavigationDrawer"
        v-if="!showNavigationDrawer"
      >
      </v-app-bar-nav-icon>

      <v-img
        :src="require('@/logo.png')"
        max-height="40"
        max-width="40"
        contain
      >
      </v-img>

      <v-toolbar-title>
        PigBrother
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title v-if="$store.getters['backend/getSelectedBarn']">{{
        $store.getters["backend/getSelectedBarn"].name
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <v-alert type="warning" prominent v-if="showNoBarnSelectedWarning">
        <v-row align="center" dense no-gutters>
          <v-col class="grow">
            No Barn selected!
          </v-col>
          <v-col class="shrink">
            <v-btn v-on:click="goTobarns">Select Barn</v-btn>
          </v-col>
        </v-row>
      </v-alert>

      <v-alert type="error" prominent v-if="showOfflineWarning">
        <v-row align="center" dense no-gutters>
          <v-col class="grow">
            Selected Barn is Offline!
          </v-col>
          <v-col class="shrink">
            <v-btn v-on:click="goTobarns">Go To Barns</v-btn>
          </v-col>
        </v-row>
      </v-alert>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},
  computed: {
    showNoBarnSelectedWarning() {
      return !this.$store.getters["backend/getSelectedBarn"];
    },
    showOfflineWarning() {
      if (!this.$store.getters["backend/getSelectedBarn"]) return false;
      return !this.$store.getters["backend/isBarnOnline"](
        this.$store.getters["backend/getSelectedBarn"]._id
      );
    },
    showBackArrow() {
      return (
        !this.navigationDrawerEnabled &&
        this.$router.currentRoute.name !== "Dashboard"
      );
    },
    navigationDrawerEnabled() {
      return true;
    }
  },
  data() {
    return {
      logoutLoading: false,
      showLogoutDialog: false,
      showNavigationDrawer: false
    };
  },
  methods: {
    goTobarns() {
      this.$router.push("/barns");
    },
    goToDashboard() {
      this.$router.push("/");
    },
    goToSettings() {
      this.$router.push("/settings");
    },
    goBack() {},
    async startLogout() {
      this.logoutLoading = true;
      await this.$store.dispatch("backend/logOutOfBarn", {
        id: this.$store.getters["backend/getSelectedBarn"]._id
      });
      this.showLogoutDialog = false;
      this.logoutLoading = false;
      await this.$router.replace("/signin");
    }
  },
  mounted() {
    this.$store.dispatch("backend/restartTinyPoke");
  },
  beforeDestroy() {
    this.$store.dispatch("backend/stopTinyPoke");
  }
};
</script>

<style>
#app {
  font-family: "Quicksand", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
