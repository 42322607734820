<template>
  <v-container fill-height>
    <v-layout align-center justify-space-around>
      <v-flex xs12 sm4 md8>
        <v-card tile :loading="showThumbnail">
          <v-card-title>{{ camera.name }}</v-card-title>
          <v-card-text>
            <v-alert v-if="showThumbnail" type="info"
              >We are setting up the livestream. This may take a few
              seconds.</v-alert
            >
            <v-img
              v-if="showThumbnail && !stillFrame"
              style="width:100%; height: auto;"
              :src="fallbackImage"
            ></v-img>
            <v-img
              v-if="showThumbnail && stillFrame"
              style="width:100%; height: auto;"
              :src="stillFrame.frame"
            ></v-img>
            <video
              style=" width: 100%;
          height: auto;"
              ref="vid"
              muted
              autoplay
              playsinline
              preload="none"
            />
          </v-card-text>
        </v-card> </v-flex></v-layout
  ></v-container>
</template>

<script>
const Hls = require("hls.js");

export default {
  name: "VideoStream",
  data() {
    return {
      fallbackImage: require("@/assets/vv_logo.png"),
      showThumbnail: true,
      hls: null,
      id: this.$route.params.id
    };
  },
  async mounted() {
    await this.startStream();
    this.attachToStream();
  },
  computed: {
    camera() {
      return this.$store.getters["cameras/getCamera"](this.id);
    },

    stillFrame() {
      return this.$store.getters["cameras/getStillFrame"](this.id);
    }
  },
  beforeDestroy() {
    try {
      this.hls.detachMedia();
    } catch {
      //no need to do anything about it
    }
    try {
      this.hls.stopLoad();
    } catch {
      //no need to do anything about it
    }
    try {
      this.hls.destroy();
    } catch {
      //no need to do anything about it
    }
    try {
      clearInterval(this.refreshTimer);
    } catch {
      //
    }
  },
  methods: {
    async startStream() {
      this.showThumbnail = true;
      this.refreshTimer = setInterval(this.refreshThumbnail, 600);
      this.path = await this.$store
        .dispatch("backend/sendAuthedRequest", {
          path: "/api/camera",
          barnId: this.$store.getters["backend/getSelectedBarn"]._id,
          payload: { cameraId: this.id }
        })
        .then(res => {
          return res.json().then(body => {
            return body.path;
          });
        });
    },
    async refreshThumbnail() {
      await this.$store.dispatch("cameras/loadThumbnail", {
        cameraId: this.id,
        force: true
      });
    },
    attachToStream() {
      const video = this.$refs["vid"];
      try {
        if (Hls.isSupported()) {
          this.hls = new Hls();
          this.hls.config.manifestLoadingMaxRetry = 100;
          this.hls.config.manifestLoadingRetryDelay = 1000;
          this.hls.on(Hls.Events.Error, (event, data) => {
            console.log("HLS error: ", event, data);
          });
          this.hls.attachMedia(video);
          this.hls.on(Hls.Events.MEDIA_ATTACHED, () => {
            let url = this.$store.getters["backend/getSelectedBackendUrl"];
            url.pathname = this.path + "camera.m3u8";
            this.hls.loadSource(url.toString());
            this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
              clearInterval(this.refreshTimer);
              this.showThumbnail = false;
              video.play();
            });
            this.hls.on(Hls.Events.MEDIA_DETACHING, () => {});
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>

<style scoped></style>
