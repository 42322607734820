<template>
  <v-container fill-height>
    <v-layout align-center justify-space-around>
      <v-flex xs12>
        <v-card>
          <v-card-title>
            Available Cameras ({{ cameras.length }})
          </v-card-title>
          <v-card-text>
            <v-switch
              label="Auto-Refresh Images"
              v-on:change="switchAutoRefresh"
              v-model="autoRefresh"
            ></v-switch>
            <v-row align="center" justify="center" dense>
              <v-col
                xs="4"
                md="4"
                lg="4"
                v-for="camera in cameras"
                v-bind:key="camera._id"
              >
                <v-card>
                  <v-img
                    v-on:click="goToStream(camera._id)"
                    class="white--text"
                    gradient="to bottom, rgba(0,0,0,.0), rgba(0,0,0,.4)"
                    cover
                    v-if="stillFrames[camera._id]"
                    :src="stillFrames[camera._id].frame"
                  >
                    <v-card-title
                      class="align-end fill-height font-weight-bold"
                      >{{ camera.name }}</v-card-title
                    >
                  </v-img>
                  <v-img
                    v-else
                    :src="fallbackImage"
                    class="white--text"
                    gradient="to bottom, rgba(0,0,0,.0), rgba(0,0,0,.4)"
                    cover
                    v-on:click="goToStream(camera._id)"
                  >
                    <v-card-title
                      class="align-end fill-height font-weight-bold"
                      >{{ camera.name }}</v-card-title
                    >
                  </v-img>
                  <v-card-actions>
                    <v-btn rounded block v-on:click="goToCameraData(camera._id)"
                      >Show Insights</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn rounded block class="primary" v-on:click="goToCameraCreation"
              >Create new Camera</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "Cameras",
  data() {
    return {
      autoRefresh: false,
      fallbackImage: require("@/assets/vv_logo.png"),
      refreshTimer: null
    };
  },
  async mounted() {
    await this.$store.dispatch("cameras/loadCameras");
  },
  computed: {
    cameras() {
      return Object.values(this.$store.getters["cameras/getStreamableCameras"]);
    },
    stillFrames() {
      return this.$store.getters["cameras/getStillFrames"];
    }
  },
  methods: {
    goToCameraCreation() {
      this.$router.push({ name: "CreateCamera", params: {} });
    },
    goToStream(cameraId) {
      this.$router.push({ name: "VideoStream", params: { id: cameraId } });
    },
    switchAutoRefresh() {
      if (this.autoRefresh === true) {
        this.refreshTimer = setInterval(this.refreshImages, 10000);
      } else {
        clearInterval(this.refreshTimer);
      }
    },
    async refreshImages() {
      await this.$store.dispatch("cameras/reloadThumbnails");
    },
    goToCameraData(cameraId) {
      this.$router.push({ name: "Data", params: { cameraId: cameraId } });
    }
  },
  beforeDestroy() {
    try {
      clearInterval(this.refreshTimer);
    } catch (e) {
      console.log(e)
      //do nothing
    }
  }
};
</script>

<style scoped></style>
