<template>
  <v-container fill-height>
    <v-layout align-center justify-space-around>
      <v-flex xs12 md8>
        <v-card>
          <v-card-title>
            Registered Users at
            {{ $store.getters["backend/getBarn"](id).name }}
          </v-card-title>

          <v-card-text>
            <User
              v-for="user of users"
              v-bind:key="user._id"
              :user="user"
              :barn_id="id"
              v-on:reload_list="reloadList"
            ></User>
          </v-card-text>
        </v-card> </v-flex
    ></v-layout>
  </v-container>
</template>

<script>
import User from "@/components/User";
export default {
  name: "People",
  components: { User },
  data() {
    return {
      id: this.$route.params.barnId
    };
  },
  mounted() {
    this.reloadList();
  },
  methods: {
    reloadList() {
      this.$store.dispatch("backend/loadUserListOfBarn", { barnId: this.id });
    }
  },
  computed: {
    users() {
      return Object.values(
        this.$store.getters["backend/getUserListOfBarn"](this.id)
      );
    }
  }
};
</script>

<style scoped></style>
