var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fill-height":""}},[_c('v-layout',{attrs:{"align-center":"","justify-space-around":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',[_c('v-card-title',[_vm._v("Select your barn")]),_c('v-card-text',[_vm._l((_vm.barns),function(barn){return _c('v-card',{key:barn._id,staticClass:"my-2",staticStyle:{"border-radius":"16px"},attrs:{"elevation":"8"}},[_c('v-card-title',[_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',{staticClass:"grow"},[_c('v-layout',{attrs:{"justify-start":""}},[_vm._v(" "+_vm._s(barn.name)+" ")])],1),(
                    _vm.$store.getters['backend/getSelectedBarn'] &&
                      _vm.$store.getters['backend/getSelectedBarn']._id ===
                        barn._id
                  )?_c('v-col',{staticClass:"shrink"},[_c('v-chip',{attrs:{"small":""}},[_vm._v("Selected")])],1):_vm._e()],1)],1),_c('v-card-text',[_c('v-row',[_c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-col',[_c('v-btn',_vm._g({attrs:{"color":"primary","rounded":""},on:{"click":function($event){return _vm.editBarn(barn)}}},on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("edit")]),_vm._v(" Edit ")],1)],1)]}}],null,true),model:{value:(_vm.editBarnDialog),callback:function ($$v) {_vm.editBarnDialog=$$v},expression:"editBarnDialog"}},[(_vm.barnToEdit)?_c('v-card',[_c('v-card-title',[_vm._v("Edit Barn")]),_c('v-card-text',[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Name of Barn"},model:{value:(_vm.barnToEdit.name),callback:function ($$v) {_vm.$set(_vm.barnToEdit, "name", $$v)},expression:"barnToEdit.name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-switch',{attrs:{"label":"Show Discovery Details"},model:{value:(_vm.showDiscoveryDetails),callback:function ($$v) {_vm.showDiscoveryDetails=$$v},expression:"showDiscoveryDetails"}})],1),(_vm.showDiscoveryDetails)?_c('v-col',{attrs:{"cols":"12"}},[_c('qrcode',{attrs:{"value":_vm.barnToEdit.discoveryString}})],1):_vm._e(),(_vm.showDiscoveryDetails)?_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"outlined":"","readonly":""},model:{value:(_vm.barnToEdit.discoveryString),callback:function ($$v) {_vm.$set(_vm.barnToEdit, "discoveryString", $$v)},expression:"barnToEdit.discoveryString"}})],1):_vm._e(),(_vm.showDiscoveryDetails)?_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"my-3",attrs:{"outlined":"","rounded":""},on:{"click":_vm.copyDiscoveryString}},[_vm._v("Copy")])],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',_vm._g({staticClass:"error",attrs:{"rounded":""}},on),[_vm._v("Delete Barn")])],1)]}}],null,true),model:{value:(_vm.deleteBarnDialog),callback:function ($$v) {_vm.deleteBarnDialog=$$v},expression:"deleteBarnDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Delete Barn?")]),_c('v-card-text',[_c('v-alert',{attrs:{"type":"warning","prominent":""}},[_vm._v(" This will remove the barn from the App! ")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.deleteBarnDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.deleteBarn}},[_vm._v(" Confirm ")])],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.editBarnDialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"primary ","text":""},on:{"click":_vm.saveBarnChanges}},[_vm._v(" Save ")])],1)],1):_vm._e()],1),(
                    _vm.$store.getters['backend/getRole'](barn._id) === 'admin' ||
                      _vm.$store.getters['backend/getRole'](barn._id) ===
                        'owner' ||
                      _vm.$store.getters['backend/getRole'](barn._id) ===
                        'manager'
                  )?_c('v-col',[_c('v-btn',{staticClass:"primary",attrs:{"rounded":""},on:{"click":function($event){return _vm.goToPeopleManagement(barn._id)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("people")]),_vm._v(" People")],1)],1):_vm._e(),_c('v-col',[(
                      _vm.$store.getters['backend/isLoggedInIntoBarn'](barn._id)
                    )?_c('v-dialog',{attrs:{"width":"500","persistent":_vm.logoutLoading},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"error",attrs:{"rounded":""}},on),[_c('v-icon',{staticClass:"ma-2"},[_vm._v("exit_to_app")]),_vm._v(" Logout ")],1)]}}],null,true),model:{value:(_vm.showLogoutDialog),callback:function ($$v) {_vm.showLogoutDialog=$$v},expression:"showLogoutDialog"}},[_c('v-card',{attrs:{"loading":_vm.logoutLoading,"disabled":_vm.logoutLoading}},[_c('v-card-title',[_vm._v("Log out of "+_vm._s(_vm.$store.getters["backend/getSelectedBarn"].name)+"?")]),_c('v-card-text',[_vm._v(" Are you sure you want to log out of this barn? You can return at any time. ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showLogoutDialog = false}}},[_vm._v("Cancel")]),_c('v-spacer'),_c('v-btn',{staticClass:"primary",attrs:{"text":""},on:{"click":function($event){return _vm.startLogout(barn._id)}}},[_vm._v("Confirm ")])],1)],1)],1):_c('v-btn',{staticClass:"success",attrs:{"rounded":""},on:{"click":function($event){return _vm.goToSignIn(barn._id)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("person")]),_vm._v(" Login ")],1)],1)],1),_c('v-layout',{staticClass:"mt-4",attrs:{"justify-end":""}},[(_vm.$store.getters['backend/isBarnOnline'](barn._id))?_c('v-chip',{attrs:{"outlined":"","color":"success"},on:{"click":function($event){return _vm.pokeBarn(barn._id)}}},[_c('p',{staticClass:"ma-1"},[_vm._v("Online")]),_c('span',{staticClass:"onlineDot ma-1"})]):_c('v-chip',{attrs:{"outlined":"","color":"error"},on:{"click":function($event){return _vm.pokeBarn(barn._id)}}},[_c('p',{staticClass:"ma-1"},[_vm._v("Offline")]),_c('span',{staticClass:"offlineDot ma-1"})])],1)],1),(
                !_vm.$store.getters['backend/getSelectedBarn'] ||
                  _vm.$store.getters['backend/getSelectedBarn']._id !== barn._id
              )?_c('v-card-actions',[_c('v-btn',{attrs:{"block":"","rounded":""},on:{"click":function($event){return _vm.$store.dispatch('backend/selectBarn', barn._id)}}},[_vm._v(" Select this barn ")])],1):_vm._e()],1)}),(_vm.barns.length === 0)?_c('v-btn',{attrs:{"rounded":"","color":"primary"},on:{"click":function($event){_vm.addBarnDialog = true}}},[_vm._v("Add a Barn ")]):_vm._e()],2)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","color":"primary","fixed":"","right":"","bottom":""}},on),[_c('v-icon',[_vm._v("add")])],1)]}}]),model:{value:(_vm.addBarnDialog),callback:function ($$v) {_vm.addBarnDialog=$$v},expression:"addBarnDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Add new Barn")]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Name of Barn"},model:{value:(_vm.newName),callback:function ($$v) {_vm.newName=$$v},expression:"newName"}})],1),_c('v-col',[_c('v-textarea',{attrs:{"outlined":"","label":"Discovery-String"},model:{value:(_vm.newDiscovery),callback:function ($$v) {_vm.newDiscovery=$$v},expression:"newDiscovery"}})],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({},on),[_vm._v(" Open QR Reader ")])]}}]),model:{value:(_vm.qrDialog),callback:function ($$v) {_vm.qrDialog=$$v},expression:"qrDialog"}},[_c('v-card',[_c('v-card-text',[(_vm.showStream)?_c('qrcode-stream',{on:{"decode":_vm.onDecode,"init":_vm.onInit}}):_c('v-container',[_c('qrcode-drop-zone',{on:{"decode":_vm.onDecode}},[_c('v-card',{staticClass:"ma-4",staticStyle:{"border-radius":"0"},attrs:{"elevation":"10"}},[_c('v-card-text',[_vm._v("Drop Image here")])],1),_c('p',[_vm._v("or")])],1),_c('qrcode-capture',{attrs:{"multiple":false},on:{"decode":_vm.onDecode}})],1)],1),(_vm.showScanAlert)?_c('v-alert',{attrs:{"type":_vm.scanAlertType}},[_vm._v(_vm._s(_vm.scanAlertText))]):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.qrDialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"primary ","text":""},on:{"click":_vm.addQrCode}},[_vm._v(" Save ")])],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.closeDialog}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"primary ","text":""},on:{"click":_vm.saveNewBarn}},[_vm._v(" Save ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }