import Vue from "vue";
import VueRouter from "vue-router";
import SignIn from "@/views/SignIn";
import SignUp from "@/views/SignUp";
import Home from "@/views/Home";
import store from "../store/index.js";
import Cameras from "@/views/Cameras";
import VideoStream from "@/views/VideoStream";
import Settings from "@/views/Settings";
import Barns from "@/views/Barns";
import People from "@/views/People";
import CreateCamera from "@/views/CreateCamera";
import Data from "@/views/Data";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Home,
    redirect: "/cameras"
    /*meta: {
      requiresAuth: true,
      requiresSelectedBarn: true
    }*/
  },
  {
    path: "/signin",
    name: "SignIn",
    component: SignIn,
    meta: {
      requiresSelectedBarn: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters["backend/isLoggedInIntoSelectedBarn"])
        return router.push("/");
      next();
    }
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUp,
    meta: { requiresSelectedBarn: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["backend/isLoggedInIntoSelectedBarn"])
        return router.push("/");
      next();
    }
  },

  {
    path: "/cameras",
    name: "Cameras",
    component: Cameras,
    meta: {
      requiresAuth: true,
      requiresSelectedBarn: true
    }
  },
  {
    path: "/data/:cameraId",
    name: "Data",
    component: Data,
    meta: {
      requiresAuth: true,
      requiresSelectedBarn: true
    }
  },
  {
    path: "/create-camera",
    name: "CreateCamera",
    component: CreateCamera,
    meta: {
      requiresAuth: true,
      requiresSelectedBarn: true
    }
  },
  {
    path: "/barns/:barnId/people",
    name: "People",
    component: People
  },
  {
    path: "/videostream/:id",
    name: "VideoStream",
    component: VideoStream,
    meta: {
      requiresAuth: true,
      requiresSelectedBarn: true
    }
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings
  },

  {
    path: "/barns",
    name: "Barns",
    component: Barns
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters["backend/isLoggedInIntoSelectedBarn"]) {
      router.push("/signin");
    } else {
      next();
    }
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresSelectedBarn)) {
    if (!store.getters["backend/getSelectedBarn"]) {
      router.push("/barns");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
